import { useState } from "react";
import {
  FiHome,
  FiMenu,
  FiStar,
  FiFileText,
  FiTool,
  FiHelpCircle,
  FiClipboard,
  FiPhone,
  FiAlignJustify,
  FiFilm,
  FiList,
} from "react-icons/fi";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [testimonialClicked, setTestimonialClicked] = useState(false);
  const [articleClicked, setArticleClicked] = useState(false);
  const [serviceClicked, setServiceClicked] = useState(false);
  const [featureClicked, setFeatureClicked] = useState(false);
  const [categoriesClicked, setCategoriesClicked] = useState(false);
  const [productsClicked, setProductsClicked] = useState(false);
  const [faqClicked, setFaqClicked] = useState(false);
  const [lineClicked, setLineClicked] = useState(false);
  const [bannerClicked, setBannerClicked] = useState(false);
  const [couponClicked, setCouponClicked] = useState(false);
  const handleToggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const handleToggle = (state, setState) => () => {
    setState(!state);
  };

  return (
    <div className={`sidebar ${showSidebar ? "open" : "closed"}`}>
      <button
        className={`fixed left-8 top-2 z-50 bg-blue-600 text-white p-2 rounded-md transition-transform ${
          showSidebar ? "translate-x-40" : ""
        }`}
        onClick={handleToggleSidebar}
      >
        <FiMenu className="w-6 h-6" />
      </button>

      <div
        className={`relative top-0 sidebarHeight bottom-0 left-0 flex flex-col w-60 bg-white shadow transform-gpu transition-transform ${
          showSidebar ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex items-center justify-between p-4 bg-white shadow">
          <h2 className="text-xl font-bold">Dashboard</h2>
        </div>
        <ul className="flex-1 pt-4 pb-8 space-y-1 text-sm">
          <li>
            <Link
              to="/"
              className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
            >
              <FiHome className="w-6 h-6" />
              <span className="ml-2">Home</span>
            </Link>
          </li>
          <li>
            <Link
              to="/contactdata"
              className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
            >
              <FiPhone className="w-6 h-6" />
              <span className="ml-2">Contact Data</span>
            </Link>
          </li>

          <li>
            <Link
              to="/surveydata"
              className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
            >
              <FiClipboard className="w-6 h-6" />
              <span className="ml-2">Survey Data</span>
            </Link>
          </li>
          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(articleClicked, setArticleClicked)}
            >
              <FiFileText className="w-6 h-6" />
              <span className="ml-2">Articles</span>
            </div>
            {articleClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showarticles"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/addarticle"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Add New</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(testimonialClicked, setTestimonialClicked)}
            >
              <FiStar className="w-6 h-6" />
              <span className="ml-2">Testimonials</span>
            </div>
            {testimonialClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showtestimonials"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/addtestimonial"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Add New</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(serviceClicked, setServiceClicked)}
            >
              <FiTool className="w-6 h-6" />
              <span className="ml-2">Services</span>
            </div>
            {serviceClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showservices"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/addservice"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Add New</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(featureClicked, setFeatureClicked)}
            >
              <FiStar className="w-6 h-6" />
              <span className="ml-2">Salient Features</span>
            </div>
            {featureClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showsilentfeatures"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/addsilentfeature"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Add New</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(categoriesClicked, setCategoriesClicked)}
            >
              <FiList className="w-6 h-6" />
              <span className="ml-2">Categories</span>
            </div>
            {categoriesClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showcategories"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(productsClicked, setProductsClicked)}
            >
              <MdOutlineProductionQuantityLimits className="w-6 h-6" />
              <span className="ml-2">Products</span>
            </div>
            {productsClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showproducts"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(faqClicked, setFaqClicked)}
            >
              <FiHelpCircle className="w-6 h-6" />
              <span className="ml-2">FAQs</span>
            </div>
            {faqClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showfaqs"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/addfaq"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Add New</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(lineClicked, setLineClicked)}
            >
              <FiAlignJustify className="w-6 h-6" />
              <span className="ml-2">Line</span>
            </div>
            {lineClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showline"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/addline"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Add New</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(bannerClicked, setBannerClicked)}
            >
              <FiFilm className="w-6 h-6" />
              <span className="ml-2">Banner</span>
            </div>
            {bannerClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showbanners"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/addbanner"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Add New</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div
              className="flex cursor-pointer items-center px-4 py-3 rounded-md hover:bg-gray-100 focus:outline-none"
              onClick={handleToggle(couponClicked, setCouponClicked)}
            >
              <FiFilm className="w-6 h-6" />
              <span className="ml-2">Coupon</span>
            </div>
            {couponClicked && (
              <ul className="pl-6 mt-2 space-y-1">
                <li>
                  <Link
                    to="/showcoupon"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Show All</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/addcoupon"
                    className="flex items-center px-4 py-3 rounded-md hover:bg-gray-100"
                  >
                    <span className="ml-2">Add New</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
