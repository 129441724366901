import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const ShowFAQ = () => {
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch(`${serverURL}faqs`);
        if (response.ok) {
          const data = await response.json();
          setFaqs(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchFaqs();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this FAQ. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        const response = await fetch(`${serverURL}faqs/${id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          setFaqs(faqs.filter((faq) => faq._id !== id));
          Swal.fire("Deleted!", "The FAQ has been deleted.", "success");
        } else {
          console.error("Error:", response.status);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className="flex">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="flex-grow transition-transform duration-300 ease-in-out">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-6">FAQs</h2>
          {faqs.length === 0 ? (
            <p>No FAQs found.</p>
          ) : (
            <ul className="space-y-8">
              {faqs.map((faq) => (
                <li key={faq._id} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex justify-between items-start mb-4">
                    <div className="flex flex-col ml-4">
                      <p className="text-xl font-semibold mb-2"><b>Q: {faq.question}</b></p>
                      <p className="text-gray-700 mb-6">A: {faq.answer.slice(0, 150)}...</p>
                    </div>
                    <div className="flex">
                      <button
                        onClick={() => handleDelete(faq._id)}
                        className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md mr-2"
                      >
                        Delete
                      </button>
                      <Link
                        to={`/faq/${faq._id}`}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowFAQ;
