import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const ShowLine = () => {
  const [lines, setLines] = useState([]);

  useEffect(() => {
    const fetchLines = async () => {
      try {
        const response = await fetch(`${serverURL}line`);
        if (response.ok) {
          const data = await response.json();
          setLines(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchLines();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this line. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        const response = await fetch(`${serverURL}line/${id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          setLines(lines.filter((line) => line._id !== id));
          Swal.fire("Deleted!", "The line has been deleted.", "success");
        } else {
          console.error("Error:", response.status);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className="flex">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="flex-grow transition-transform duration-300 ease-in-out">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-6">Line</h2>
          {lines.length === 0 ? (
            <p>No Line found.</p>
          ) : (
            <ul className="space-y-8">
              {lines.map((line) => (
                <li key={line._id} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex justify-between items-start mb-4">
                    <div className="flex flex-col ml-4">
                      <p className="text-xl font-semibold mb-2"><b>{line.content}</b></p>
                    </div>
                    <div className="flex">
                      <button
                        onClick={() => handleDelete(line._id)}
                        className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md mr-2"
                      >
                        Delete
                      </button>
                      <Link
                        to={`/line/${line._id}`}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowLine;
