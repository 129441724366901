import React from 'react';
import Sidebar from './Sidebar';

const Home = () => {
  return (
    <div className="flex">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className={`flex-grow transition-transform duration-300 ease-in-out`}>
        <div className="p-4">
          <h1 className="text-2xl font-bold">Welcome to the Home Page</h1>
          <p className="mt-4">
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
