import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";

import Home from "./Components/Home";
import AddArticle from "./Components/AddArticle";
import ShowArticles from "./Components/ShowArticles";
import EditArticle from "./Components/EditArticle";
import AddTestimonial from "./Components/AddTestimonial";
import ShowTestimonials from "./Components/ShowTestimonial";
import EditTestimonial from "./Components/EditTestimonial";
import AddService from "./Components/AddService";
import ShowServices from "./Components/ShowService";
import EditService from "./Components/EditService";
import AddSilentFeature from "./Components/AddSilentFeature";
import ShowSilentFeatures from "./Components/ShowSilentFeature";
import EditSilentFeature from "./Components/EditSilentFeature";
import AddFAQ from "./Components/AddFAQ";
import ShowFAQ from "./Components/ShowFAQ";
import EditFAQ from "./Components/EditFAQ";
import Table from "./Components/Table.tsx";
import TableHero from "./Components/Tablehero.tsx";
import AddLine from "./Components/AddLine";
import AddBanner from "./Components/AddBanner";
import ShowLine from "./Components/ShowLine";
import ShowBanner from "./Components/ShowBanner";
import EditLine from "./Components/EditLine";
import EditBanner from "./Components/EditBanner";

// Import the new components
import AddCoupon from "./Components/AddCoupon";
import ShowCoupon from "./Components/ShowCoupon";
import ShowCategories from "./Components/ShowCategories";
import ShowProducts from "./Components/ShowProducts";
const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/addarticle" element={<AddArticle />} />
          <Route path="/showarticles" element={<ShowArticles />} />
          <Route path="/article/:id" element={<EditArticle />} />
          <Route path="/addtestimonial" element={<AddTestimonial />} />
          <Route path="/showtestimonials" element={<ShowTestimonials />} />
          <Route path="/testimonial/:id" element={<EditTestimonial />} />
          <Route path="/addservice" element={<AddService />} />
          <Route path="/showservices" element={<ShowServices />} />
          <Route path="/service/:id" element={<EditService />} />
          <Route path="/addsilentfeature" element={<AddSilentFeature />} />
          <Route path="/showsilentfeatures" element={<ShowSilentFeatures />} />
          <Route path="/silentfeature/:id" element={<EditSilentFeature />} />
          <Route path="/addfaq" element={<AddFAQ />} />
          <Route path="/showfaqs" element={<ShowFAQ />} />
          <Route path="/faq/:id" element={<EditFAQ />} />
          <Route path="/contactdata" element={<Table />} />

          <Route path="/showcategories" element={<ShowCategories />} />

          <Route path="/showproducts" element={<ShowProducts />} />
          {/* <Route path="/addCategory" element={<Table/>} /> */}

          <Route path="/surveydata" element={<TableHero />} />
          <Route path="/showline" element={<ShowLine />} />
          <Route path="/line/:id" element={<EditLine />} />
          <Route path="/addline" element={<AddLine />} />
          <Route path="/addbanner" element={<AddBanner />} />
          <Route path="/showbanners" element={<ShowBanner />} />
          <Route path="/banner/:id" element={<EditBanner />} />
          <Route path="/addcoupon" element={<AddCoupon />} />
          <Route path="/showcoupon" element={<ShowCoupon />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
