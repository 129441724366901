import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const ShowCoupon = () => {
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await fetch(`${serverURL}coupons`);
        if (response.ok) {
          const data = await response.json();
          setCoupons(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchCoupons();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this coupon. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
  
      if (result.isConfirmed) {
        const response = await fetch(`${serverURL}coupon/${id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          setCoupons(coupons.filter((coupon) => coupon._id !== id));
          Swal.fire("Deleted!", "The coupon has been deleted.", "success");
        } else {
          console.error("Error:", response.status);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className="flex">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="flex-grow transition-transform duration-300 ease-in-out">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-6">Coupons</h2>
          {coupons.length === 0 ? (
            <p>No coupons found.</p>
          ) : (
            <ul className="space-y-8">
              {coupons.map((coupon) => (
                <li key={coupon._id} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <p>ID: {coupon.id}</p>
                      <p>Discount Text: {coupon.discountText}</p>
                      <p>Restaurant Name: {coupon.restaurantName}</p>
                      <p>Offer Description: {coupon.offerDescription}</p>
                      <p>Validity: {coupon.validity}</p>
                    </div>
                    <div className="flex space-x-4">
                      <button
                        onClick={() => handleDelete(coupon._id)}
                        className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md"
                      >
                        Delete
                      </button>
                      <Link
                        to={`/coupon/${coupon._id}`}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowCoupon;
