import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const AddCoupon = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: "",
        discountText: "",
        restaurantName: "",
        offerDescription: "",
        validity: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${serverURL}coupons`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const coupon = await response.json();
                Swal.fire(
                    "Congrats",
                    "Coupon added successfully!",
                    "success"
                ).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/showcoupons");
                    }
                });
            } else {
                const error = await response.json();
                console.error("Error:", error);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-center">Add a Coupon</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mt-6">
                        <label htmlFor="id" className="block mb-2">
                            Coupon ID:
                        </label>
                        <input
                            type="text"
                            id="id"
                            name="id"
                            value={formData.id}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="discountText" className="block mb-2">
                            Discount Text:
                        </label>
                        <input
                            type="text"
                            id="discountText"
                            name="discountText"
                            value={formData.discountText}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="restaurantName" className="block mb-2">
                            Restaurant Name:
                        </label>
                        <input
                            type="text"
                            id="restaurantName"
                            name="restaurantName"
                            value={formData.restaurantName}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="offerDescription" className="block mb-2">
                            Offer Description:
                        </label>
                        <textarea
                            id="offerDescription"
                            name="offerDescription"
                            value={formData.offerDescription}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="mt-6">
                        <label htmlFor="validity" className="block mb-2">
                            Validity:
                        </label>
                        <input
                            type="text"
                            id="validity"
                            name="validity"
                            value={formData.validity}
                            onChange={handleChange}
                            className="border rounded p-2 mb-2"
                            required
                        />
                    </div>
                    <div className="flex justify-center mt-6">
                        <button
                            type="submit"
                            className="bg-blue-500 w-[100%] hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCoupon;
