import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from './Sidebar';
import { serverURL } from "../serverUrl"

const Table = () => {
  const [data, setData] = useState([]);
  const [sortOrder, setSortOrder] = useState({ column: '', direction: 'asc' });
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${serverURL}contactform`);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSort = (column: string) => {
    setSortOrder(prevState => ({
      column,
      direction: prevState.column === column && prevState.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  useEffect(() => {
    const sortedData = data.slice().sort((a, b) => {
      const column = sortOrder.column;
      if (a[column] < b[column]) return sortOrder.direction === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return sortOrder.direction === 'asc' ? 1 : -1;
      return 0;
    });
    setData(sortedData);
  }, [sortOrder]);

  interface MyObject {
    [key: string]: any;
  }

  function convertArrayToCSV(data: MyObject[], filename: string) {
    const csvContent = generateCSVContent(data);
    const csvData = new Blob([csvContent], { type: 'text/csv' });

    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(csvData);
    downloadLink.setAttribute('download', filename);

    downloadLink.click();
  }

  function generateCSVContent(data: MyObject[]): string {
    const keys = Object.keys(data[0]);

    const headerRow = keys.join(',') + '\n';

    const rows = data.map(obj => {
      const values = keys.map(key => {
        const value = obj[key];

        if (Array.isArray(value)) {
          return `"${value.join(', ')}"`;
        }

        if (value && value.toString().includes(',')) {
          return `"${value}"`;
        }

        return value;
      });

      return values.join(',');
    });

    return headerRow + rows.join('\n');
  }
  const proper = (old: any) => {
    const date = new Date(old);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime
  }
  return (
    <div className="flex">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className={`flex-grow transition-transform duration-300 ease-in-out`}>
      <h1 className="text-3xl font-semibold mb-4">Contact Us Form Data</h1>
      <button
        onClick={() => convertArrayToCSV(data, "contactData")}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Save to CSV file
      </button>
      <table className="w-full mt-4 border border-gray-400">
      <thead>
          <tr className="bg-gray-200">
            <th className="p-2">S.No.</th>
            <th className="p-2" onClick={() => handleSort("name")} style={{ cursor: "pointer",   }}>
              Name {sortOrder.column === "name" && sortOrder.direction === "asc" ? <>&uarr;</> : <>&darr;</>}
            </th>
            <th className="p-2" style={{ cursor: "pointer",   }}>
              Email
            </th>
            <th className="p-2" style={{ cursor: "pointer",   }}>
              Phone
            </th>
            <th className="p-2" style={{ cursor: "pointer",   }}>
              Can contact
            </th>
            <th className="p-2" onClick={() => handleSort("timeslot")} style={{ cursor: "pointer",   }}>
              Timeslot {sortOrder.column === "timeslot" && sortOrder.direction === "asc" ? <>&uarr;</> : <>&darr;</>}
            </th>
            <th className="p-2" style={{ cursor: "pointer",   }}>
              Feedback/Query
            </th>
            <th className="p-2" onClick={() => handleSort("createdAt")} style={{ cursor: "pointer",   }}>
              Timestamp {sortOrder.column === "createdAt" && sortOrder.direction === "asc" ? <>&uarr;</> : <>&darr;</>}
            </th>
          </tr>
        </thead>
        <tbody>
        {data.map((item: any, i) => (
            <tr key={item._id} className={i % 2 === 0 ? 'bg-gray-100' : ''}>
              <td className="p-2">{i + 1}</td>
              <td className="p-2">{item.name}</td>
              <td className="p-2">{item.email}</td>
              <td className="p-2">{item.phone}</td>
              <td className="p-2">{JSON.stringify(item.canWeConnect)}</td>
              <td className="p-2">{item.timeslot}</td>
              <td className="p-2">{item.feedback}</td>
              <td className="p-2">{proper(item.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default Table;