import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const ShowServices = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch(`${serverURL}services`);
        if (response.ok) {
          const data = await response.json();
          setServices(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchServices();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this service. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        const response = await fetch(`${serverURL}services/${id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          setServices(services.filter((service) => service._id !== id));
          Swal.fire("Deleted!", "The service has been deleted.", "success");
        } else {
          console.error("Error:", response.status);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className="flex">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="flex-grow transition-transform duration-300 ease-in-out">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-6">Services</h2>
          {services.length === 0 ? (
            <p>No services found.</p>
          ) : (
            <ul className="space-y-8">
              {services.map((service) => (
                <li key={service._id} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex justify-between items-start mb-4">
                    <div className="w-32 h-32 bg-gray-200 overflow-hidden">
                      <img
                        src={`${serverURL}` + service.image}
                        alt={service.title}
                        className="object-cover w-full h-full"
                      />
                    </div>
                    <div className="flex flex-col ml-4">
                      <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                      <p className="text-gray-700 mb-6">{service.body.slice(0, 150)}...</p>
                    </div>
                    <div className="flex">
                        <button
                          onClick={() => handleDelete(service._id)}
                          className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md mr-2"
                        >
                          Delete
                        </button>
                        <Link
                          to={`/service/${service._id}`}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                        >
                          Edit
                        </Link>
                      </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
  
  

};

export default ShowServices;
