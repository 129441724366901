import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const AddArticle = () => {
    const navigate = useNavigate();
    const [selectedFile1, setSelectedFile1] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
    const [imagePath1, setImagePath1] = useState('');
    const [imagePath2, setImagePath2] = useState('');
    const [formData, setFormData] = useState({
        title: "",
        content: "",
        image1: "",
        image2: "",
        contentHTML: "",
    });
    const handleFileChange1 = (event) => {
        setSelectedFile1(event.target.files[0]);
    };

    const handleFileChange2 = (event) => {
        setSelectedFile2(event.target.files[0])
    };


    const handleUpload1 = async (e) => {
        e.preventDefault();
        const formD = new FormData();
        formD.append('image', selectedFile1);

        try {
            const response = await fetch(`${serverURL}upload`, {
                method: 'POST',
                body: formD,
            });

            if (response.ok) {
                const data = await response.json();
                setImagePath1(data.imagePath);
                setFormData({
                    ...formData,
                    image1: data.imagePath,
                });
                console.log('Image uploaded:', data.imagePath);
            } else {
                throw new Error('Error uploading image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };
    const handleUpload2 = async (e) => {
        e.preventDefault();
        const formD = new FormData();
        formD.append('image', selectedFile2);

        try {
            const response = await fetch(`${serverURL}upload`, {
                method: 'POST',
                body: formD,
            });

            if (response.ok) {
                const data = await response.json();
                setImagePath2(data.imagePath);
                setFormData({
                    ...formData,
                    image2: data.imagePath,
                });
                console.log('Image uploaded:', data.imagePath);
            } else {
                throw new Error('Error uploading image');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${serverURL}articles`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                const article = await response.json();

                const contentHTML = article.content.replace(/\n/g, '<br/>');
                setFormData({
                    ...formData,
                    contentHTML,
                });

                Swal.fire("Congrats", "Article created successfully!", "success").then(
                    (result) => {
                        if (result.isConfirmed) {
                            navigate("/showarticles");
                        }
                    }
                );
            } else {
                const error = await response.json();
                console.error("Error:", error);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-center">Create an Article</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label htmlFor="title" className="block text-gray-700 text-sm font-medium mb-2">
                            Title
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="image1" className="block mb-2">
                            Image 1:
                        </label>
                        <input
                            type="file"
                            id="image1"
                            name="image1"
                            onChange={handleFileChange1}
                            className="border rounded p-2 mb-2"
                        />
                        <button
                            onClick={handleUpload1}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Upload Image
                        </button>
                        {imagePath1 && (
                            <img
                                src={`${serverURL}` + imagePath1}
                                alt="Uploaded"
                                style={{ height: "200px" }}
                                className="mt-2"
                            />
                        )}
                    </div>
                    <div className="mb-6">
                        <label htmlFor="image2" className="block mb-2">
                            Image 2:
                        </label>
                        <input
                            type="file"
                            id="image2"
                            name="image2"
                            onChange={handleFileChange2}
                            className="border rounded p-2 mb-2"
                        />
                        <button
                            onClick={handleUpload2}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Upload Image
                        </button>
                        {imagePath2 && (
                            <img
                                src={`${serverURL}` + imagePath2}
                                alt="Uploaded"
                                style={{ height: "200px" }}
                                className="mt-2"
                            />
                        )}
                    </div>
                    <div className="mb-6">
                        <label htmlFor="content" className="block text-gray-700 text-sm font-medium mb-2">
                            Content
                        </label>
                        <textarea
                            id="content"
                            name="content"
                            value={formData.content}
                            onChange={handleChange}                 
                            required
                            rows="5"
                            className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="bg-blue-500 w-[100%] hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AddArticle;
