import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const ShowSilentFeatures = () => {
  const [silentFeature, setSilentFeatures] = useState([]);

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await fetch(`${serverURL}silentfeatures`);
        if (response.ok) {
          const data = await response.json();
          setSilentFeatures(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchFeatures();
  }, []);

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this silentfeature. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });
  
      if (result.isConfirmed) {
        const response = await fetch(`${serverURL}silentfeatures/${id}`, {
          method: "DELETE",
        });
        if (response.ok) {
          setSilentFeatures(silentFeature.filter((silentfeature) => silentfeature._id !== id));
          Swal.fire("Deleted!", "The silentfeature has been deleted.", "success");
        } else {
          console.error("Error:", response.status);
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className="flex">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div className="flex-grow transition-transform duration-300 ease-in-out">
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-6">Silent Feature</h2>
          {silentFeature.length === 0 ? (
            <p>No Silent Feature found.</p>
          ) : (
            <ul className="space-y-8">
              {silentFeature.map((silentfeature) => (
                <li key={silentfeature._id} className="bg-white p-6 rounded-lg shadow-md">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center space-x-4">
                      <img
                        src={`${serverURL}${silentfeature.imageL}`}
                        alt="Image 1"
                        style={{ height: "50px" }}
                      />
                      <img
                        src={`${serverURL}${silentfeature.imageD}`}
                        alt="Image 2"
                        style={{ height: "50px" }}
                      />
                    </div>
                    <div className="flex-grow flex justify-center">
                      <div className="w-[75%]">
                        <h3 className="text-xl font-semibold mb-2">{silentfeature.author}</h3>
                        <p className="text-gray-700 mb-4">{silentfeature.body.slice(0, 75)}...</p>
                      </div>
                    </div>
                    <div className="flex space-x-4">
                      <button
                        onClick={() => handleDelete(silentfeature._id)}
                        className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded-md"
                      >
                        Delete
                      </button>
                      <Link
                        to={`/silentfeature/${silentfeature._id}`}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                      >
                        Edit
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
  
  
  
}  

export default ShowSilentFeatures