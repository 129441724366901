const currencyFormatter = new Intl.NumberFormat("en-In", {
  style: "currency",
  currency: "INR",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function getCurrencyLocaleString(price) {
  let converted = currencyFormatter.format(price);
  return converted;
}
