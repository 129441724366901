import { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";
import axios from "axios";
import {
  Button,
  Label,
  Select,
  Spinner,
  Table,
  TextInput,
  Textarea,
} from "flowbite-react";
import _ from "lodash";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertFromHTML, convertToHTML } from "draft-convert";
import { getCurrencyLocaleString } from "../utility/helper";
import moment from "moment/moment";

const defaultInputProps = {
  value: "",
  error: false,
  errorMessage: "",
};

const defaultInputState = {
  name: {
    ...defaultInputProps,
    value: "test product",
  },
  shortDescription: {
    ...defaultInputProps,
    value: "",
  },
  quantity: {
    ...defaultInputProps,
    value: "",
  },
  price: {
    ...defaultInputProps,
    value: "200",
  },
  finalPrice: {
    ...defaultInputProps,
    value: "100",
  },
  discount: {
    ...defaultInputProps,
    value: "50",
  },
  description: {
    ...defaultInputProps,
  },
  category: {
    ...defaultInputProps,
    value: "6518063ba3e7044eea93dd17",
  },
};

function convertFilesToBase64(files) {
  const promises = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    promises.push(
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          const base64String = reader.result;
          resolve(base64String);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      })
    );
  }

  return Promise.all(promises);
}

const ShowProducts = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [inputs, setInputs] = useState(defaultInputState);
  const [mode, setMode] = useState(false);
  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  let amountRegex = /^[0-9]+(\.[0-9]+)?$/;

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, []);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setInputs((p) => ({
      ...p,
      description: {
        error: false,
        errorMessage: "",
        value: html,
      },
    }));
  }, [editorState]);

  useEffect(() => {
    if (mode && mode === "edit" && product) {
      let {
        name,
        description,
        category,
        price,
        discount,
        finalPrice,
        shortDescription,
        quantity,
        images,
        videos,
      } = product;
      let newEditorState = EditorState.createWithContent(
        convertFromHTML(description)
      );
      setEditorState(newEditorState);
      setInputs((p) => ({
        ...p,
        name: {
          ...defaultInputProps,
          value: name,
        },
        shortDescription: {
          ...defaultInputProps,
          value: shortDescription,
        },
        quantity: {
          ...defaultInputProps,
          value: quantity?.toString(),
        },
        category: {
          ...defaultInputProps,
          value: category._id,
        },
        price: {
          ...defaultInputProps,
          value: price.toString(),
        },
        discount: {
          ...defaultInputProps,
          value: discount?.toString() || "",
        },
        finalPrice: {
          ...defaultInputProps,
          value: finalPrice.toString(),
        },
      }));
    }
  }, [mode, product]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${serverURL}category/all`);
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        console.error("Error:", response.status);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${serverURL}product`);
      if (response.ok) {
        const data = await response.json();
        setProducts(data);
      } else {
        console.error("Error:", response.status);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleDelete = async (product) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this product. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        try {
          const response = await axios.delete(
            `${serverURL}product/${product._id}`
          );
          fetchProducts();
          Swal.fire("Delete!", "The product has been deleted.", "success");
          handleClose();
        } catch (error) {
          let message = error?.response?.data?.error || error.toString();
          Swal.fire("", message, "error");
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const onClickEdit = async (product) => {
    setProduct(product);
    setMode("edit");
  };

  const handleClose = () => {
    setMode(null);
    setProduct(null);
    setInputs(defaultInputState);
    setImages([]);
    setVideos([]);
  };

  const returnValue = (value) => {
    return typeof value === "string" ? value.trim() : value;
  };

  const onSubmit = async () => {
    if (loading) {
      return;
    }
    let {
      name,
      description,
      category,
      price,
      discount,
      finalPrice,
      shortDescription,
      quantity,
    } = inputs;
    name = returnValue(name.value);
    description = returnValue(description.value);
    shortDescription = returnValue(shortDescription.value);
    category = returnValue(category.value);
    price = returnValue(price.value);
    quantity = returnValue(quantity.value);
    discount = returnValue(discount.value);
    finalPrice = returnValue(finalPrice.value);

    let discountRegex = /^[0-9]+$/;
    let hadErrors = false;
    const setError = (key, message) => {
      setInputs((p) => ({
        ...p,
        [key]: {
          ...p[key],
          error: true,
          errorMessage: message,
        },
      }));
      hadErrors = true;
    };

    if (!name) {
      setError("name", "Name Required");
    }
    if (!description || description === "<p></p>") {
      setError("description", "Description Required");
    }

    if (!shortDescription) {
      setError("shortDescription", "Short Description Required");
    }

    if (!quantity || !discountRegex.test(quantity)) {
      setError("quantity", "Invalid format");
    }

    if (!category) {
      setError("category", "Please select category");
    }
    if (!price || !amountRegex.test(price)) {
      setError("price", "Invalid format");
    }

    if (discount && !discountRegex.test(price)) {
      setError("discount", "Invalid format");
    }

    if (!finalPrice || !amountRegex.test(finalPrice)) {
      setError("finalPrice", "Invalid format");
    }

    if (hadErrors) {
      window.scrollTo(0, 0);
      return;
    }
    try {
      let data = {
        name: name,
        description: description,
        shortDescription: shortDescription,
        category: category,
        price: Number(price),
        discount: Number(discount),
        quantity: Number(quantity),
        finalPrice: Number(finalPrice),
        images: images,
        videos: videos,
      };

      setLoading(true);
      if (mode === "create") {
        const response = await axios.post(`${serverURL}product`, data);
        Swal.fire("Added!", "The product has been added.", "success");
      }
      if (mode === "edit") {
        const response = await axios.put(
          `${serverURL}product/${product._id}`,
          data
        );
        Swal.fire("Updated!", "The product has been updated.", "success");
      }

      setLoading(false);
      handleClose();
      fetchProducts();
      handleClose();
    } catch (error) {
      setLoading(false);
      let message = error.response?.data?.error || error.toString();
      Swal.fire("", message, "error");
      console.error("Error data:", error);
    }
  };

  const onValueChangeHandler = (key, value) => {
    setInputs((p) => ({
      ...p,
      [key]: {
        ...p[key],
        error: false,
        errorMessage: "",
        value: value,
      },
      finalPrice: {
        ...p.finalPrice,
        error: false,
        errorMessage: "",
      },
    }));
  };

  useEffect(() => {
    let price = inputs.price.value;
    let discount = inputs.discount.value;
    if (price) {
      price = Number(price);
      discount = Number(discount);
      let priceValid = amountRegex.test(price);
      if (priceValid) {
        if (discount && /^[0-9]+$/.test(discount)) {
          let discountApplied = price * (discount / 100);
          let finalPrice = price - discountApplied;
          setInputs((p) => ({
            ...p,
            finalPrice: {
              ...p.finalPrice,
              value: finalPrice.toString(),
            },
          }));
        } else {
          setInputs((p) => ({
            ...p,
            finalPrice: {
              ...p.finalPrice,
              value: price.toString(),
            },
          }));
        }
      }
    }
  }, [inputs.price.value, inputs.discount.value]);

  const onChangeImages = async (files) => {
    if (files && files.length > 0) {
      let base64Strings = await convertFilesToBase64(files);
      setImages(base64Strings);
    }
  };

  const onChangeVideos = async (files) => {
    if (files && files.length > 0) {
      let base64Strings = await convertFilesToBase64(files);
      setVideos(base64Strings);
    }
  };

  const onRemoveImage = (image) => {
    let currentImages = _.cloneDeep(images);
    let finalImages = currentImages.filter((i, index) => i !== image);
    setImages(finalImages);
  };

  const onRemoveProductImage = async (image) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this image. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        let data = {
          image: image,
        };

        setLoading(true);
        const response = await axios.put(
          `${serverURL}product/${product._id}/removeImage`,
          data
        );
        let result = response.data;
        console.log(result);
        setProduct(result);
        Swal.fire("Removed!", "The Image has been removed.", "success");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        let message = error?.response?.data?.error || error.toString();
        Swal.fire("", message, "error");
        console.error("Error data:", error);
      }
    }
  };

  const onRemoveProductVideo = async (video) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this video. This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      try {
        let data = {
          video: video,
        };

        setLoading(true);
        const response = await axios.put(
          `${serverURL}product/${product._id}/removeVideo`,
          data
        );
        let result = response.data;
        console.log(result);
        setProduct(result);
        Swal.fire("Removed!", "The Video has been removed.", "success");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        let message = error?.response?.data?.error || error.toString();
        Swal.fire("", message, "error");
        console.error("Error data:", error);
      }
    }
  };

  const onRemoveVideo = (video) => {
    let currentVideos = _.cloneDeep(videos);
    let finalVideos = currentVideos.filter((v, i) => v !== video);
    setVideos(finalVideos);
  };

  return (
    <>
      <div className="flex">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="flex-grow transition-transform duration-300 ease-in-out">
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-6">Products</h2>
            {!mode && (
              <>
                <Button
                  className="p-2 flex ml-auto"
                  onClick={() => setMode("create")}
                >
                  Add new product
                </Button>
                {products.length === 0 && <p>No products found.</p>}

                {products && products.length > 0 && (
                  <Table hoverable className="mt-5">
                    <Table.Head>
                      <Table.HeadCell>Product name</Table.HeadCell>
                      <Table.HeadCell>Category</Table.HeadCell>
                      <Table.HeadCell>Quantity</Table.HeadCell>
                      <Table.HeadCell>Price</Table.HeadCell>
                      <Table.HeadCell>Discount</Table.HeadCell>
                      <Table.HeadCell>Final Price</Table.HeadCell>
                      <Table.HeadCell>Created At</Table.HeadCell>
                      <Table.HeadCell>Actions</Table.HeadCell>
                    </Table.Head>
                    <Table.Body className="divide-y">
                      {products.map((product, i) => {
                        let {
                          name,
                          category,
                          price,
                          discount,
                          finalPrice,
                          createdAt,
                          quantity,
                        } = product;
                        return (
                          <Table.Row
                            key={i}
                            className="bg-white dark:border-gray-700 dark:bg-gray-800"
                          >
                            <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                              {name}
                            </Table.Cell>
                            <Table.Cell>{category?.name}</Table.Cell>
                            <Table.Cell>{quantity}</Table.Cell>
                            <Table.Cell>
                              {getCurrencyLocaleString(price)}
                            </Table.Cell>
                            <Table.Cell>{discount}%</Table.Cell>
                            <Table.Cell>
                              {getCurrencyLocaleString(finalPrice)}
                            </Table.Cell>
                            <Table.Cell>
                              {moment(createdAt).calendar()}
                            </Table.Cell>
                            <Table.Cell className="flex gap-2">
                              <div
                                onClick={() => onClickEdit(product)}
                                className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 cursor-pointer"
                              >
                                <p>Edit</p>
                              </div>
                              <div
                                onClick={() => handleDelete(product)}
                                className="font-medium text-red-500 hover:underline dark:text-red-600 cursor-pointer"
                              >
                                <p>Delete</p>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </>
            )}
          </div>
          {mode && (mode === "create" || mode === "edit") && (
            <>
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Name*
                  </label>
                  <TextInput
                    id="name"
                    value={inputs.name.value}
                    onChange={(e) =>
                      onValueChangeHandler("name", e.target.value)
                    }
                    placeholder="Enter the product name here"
                    required
                    type="text"
                  />
                  {inputs.name.error && (
                    <small className="text-[tomato]">
                      {inputs.name.errorMessage}
                    </small>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="name"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Short Description*
                  </label>
                  <Textarea
                    rows={5}
                    id="shortDescription"
                    value={inputs.shortDescription.value}
                    onChange={(e) =>
                      onValueChangeHandler("shortDescription", e.target.value)
                    }
                    placeholder="Enter the short description for preview here"
                    required
                    type="text"
                  />
                  {inputs.shortDescription.error && (
                    <small className="text-[tomato]">
                      {inputs.shortDescription.errorMessage}
                    </small>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="discount"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Quantity*
                  </label>
                  <TextInput
                    id="quantity"
                    value={inputs.quantity.value}
                    onChange={(e) =>
                      onValueChangeHandler("quantity", e.target.value)
                    }
                    placeholder="Enter the quantity available"
                    required
                    type="text"
                  />
                  {inputs.quantity.error && (
                    <small className="text-[tomato]">
                      {inputs.quantity.errorMessage}
                    </small>
                  )}
                </div>

                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="description" value="Description*" />
                  </div>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                  />
                  {inputs.description.error && (
                    <small className="text-[tomato]">
                      {inputs.description.errorMessage}
                    </small>
                  )}
                </div>

                <div id="select">
                  <div className="mb-2 block">
                    <Label htmlFor="category" value="Category*" />
                  </div>
                  <Select
                    id="category"
                    required
                    value={inputs.category.value}
                    onChange={(e) =>
                      onValueChangeHandler("category", e.target.value)
                    }
                  >
                    <option value={""}>select category</option>
                    {categories.map((category, i) => {
                      return (
                        <option key={i} value={category._id}>
                          {category.name}
                        </option>
                      );
                    })}
                  </Select>
                  {inputs.category.error && (
                    <small className="text-[tomato]">
                      {inputs.category.errorMessage}
                    </small>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="price"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Price*
                  </label>
                  <TextInput
                    id="price"
                    value={inputs.price.value}
                    onChange={(e) =>
                      onValueChangeHandler("price", e.target.value)
                    }
                    placeholder="Enter the price here"
                    required
                    type="text"
                  />
                  {inputs.price.error && (
                    <small className="text-[tomato]">
                      {inputs.price.errorMessage}
                    </small>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="discount"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Discount (%)
                  </label>
                  <TextInput
                    id="discount"
                    value={inputs.discount.value}
                    onChange={(e) =>
                      onValueChangeHandler("discount", e.target.value)
                    }
                    placeholder="Enter the discount here (optional)"
                    required
                    type="text"
                  />
                  {inputs.discount.error && (
                    <small className="text-[tomato]">
                      {inputs.discount.errorMessage}
                    </small>
                  )}
                </div>

                <div>
                  <label
                    htmlFor="name"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Final Price*
                  </label>
                  <TextInput
                    id="finalPrice"
                    readOnly
                    value={inputs.finalPrice.value}
                    onChange={(e) =>
                      onValueChangeHandler("finalPrice", e.target.value)
                    }
                    placeholder="Final Price After Discount"
                    required
                    type="text"
                  />
                  {inputs.finalPrice.error && (
                    <small className="text-[tomato]">
                      {inputs.finalPrice.errorMessage}
                    </small>
                  )}
                </div>
                {/* images */}
                <div>
                  <label
                    htmlFor="image"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Images
                  </label>
                  <TextInput
                    id="image"
                    onChange={(e) => onChangeImages(e.target.files)}
                    accept="image/*"
                    multiple
                    type="file"
                  />
                </div>

                <div className="flex gap-2">
                  {images &&
                    images.map((image, i) => {
                      return (
                        <div key={i}>
                          <img
                            src={image}
                            className="h-[200px] w-[200px] object-cover"
                            alt="..."
                          />
                          <Button
                            color="red"
                            className="p-2 mt-2 ml-5"
                            onClick={() => onRemoveImage(image)}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                </div>

                {/* edit mode */}
                {mode === "edit" &&
                  product &&
                  product.images &&
                  product.images.length > 0 && (
                    <div className="flex gap-2">
                      {product.images.map((image, i) => {
                        return (
                          <div key={i}>
                            <img
                              src={image}
                              className="h-[200px] w-[200px] object-cover"
                              alt="..."
                            />
                            <Button
                              color="red"
                              className="p-2 mt-2 ml-5"
                              onClick={() => onRemoveProductImage(image)}
                            >
                              Remove Image
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )}

                {/* videos */}
                <div>
                  <label
                    htmlFor="video"
                    className="block text-gray-700 text-sm font-medium mb-2"
                  >
                    Videos
                  </label>
                  <TextInput
                    id="video"
                    onChange={(e) => onChangeVideos(e.target.files)}
                    accept="video/mp4"
                    multiple
                    type="file"
                  />
                </div>

                <div className="flex flex-wrap gap-2">
                  {videos &&
                    videos.map((video, i) => {
                      return (
                        <div key={i}>
                          <video width={300} height={400} controls>
                            <source id="video" src={video} type="video/mp4" />
                          </video>
                          <Button
                            color="red"
                            className="p-2 mt-2 ml-5"
                            onClick={() => onRemoveVideo(video)}
                          >
                            Delete
                          </Button>
                        </div>
                      );
                    })}
                </div>

                {/* edit mode video*/}
                {mode === "edit" &&
                  product &&
                  product.videos &&
                  product.videos.length > 0 && (
                    <div className="flex gap-2">
                      {product.videos.map((video, i) => {
                        return (
                          <div key={i}>
                            <video width={300} height={400} controls>
                              <source id="video" src={video} type="video/mp4" />
                            </video>
                            <Button
                              color="red"
                              className="p-2 mt-2 ml-5"
                              onClick={() => onRemoveProductVideo(video)}
                            >
                              Remove Video
                            </Button>
                          </div>
                        );
                      })}
                    </div>
                  )}

                <div className="flex justify-end gap-2 ">
                  <Button className="p-2" onClick={onSubmit}>
                    {loading && <Spinner className="mr-3" />}
                    {loading
                      ? "Please wait"
                      : mode === "create"
                      ? "Add Product"
                      : "Update Product"}
                  </Button>
                  <Button color="#fff" className="p-2" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
                <br />
                <br />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ShowProducts;
