import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const EditSilentFeature = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [imagePath1, setImagePath1] = useState('');
  const [imagePath2, setImagePath2] = useState('');
  const [formData, setFormData] = useState({
    author: "",
    body: "",
    imageL: "",
    imageD: "",
  });

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const response = await fetch(`${serverURL}silentfeatures/${id}`);
        if (response.ok) {
          const data = await response.json();
          setFormData(data);
        } else {
          console.error("Error:", response.status);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };

    fetchFeatures();
  }, [id]);

  const handleFileChange1 = (event) => {
    setSelectedFile1(event.target.files[0]);
  };

  const handleFileChange2 = (event) => {
    setSelectedFile2(event.target.files[0]);
  };

  const handleUpload1 = async (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append('image', selectedFile1);

    try {
      const response = await fetch(`${serverURL}upload`, {
        method: 'POST',
        body: formD,
      });

      if (response.ok) {
        const data = await response.json();
        setImagePath1(data.imagePath);
        setFormData({
          ...formData,
          imageL: data.imagePath,
        });
        console.log('Image uploaded:', data.imagePath);
      } else {
        throw new Error('Error uploading image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleUpload2 = async (e) => {
    e.preventDefault();
    const formD = new FormData();
    formD.append('image', selectedFile2);

    try {
      const response = await fetch(`${serverURL}upload`, {
        method: 'POST',
        body: formD,
      });

      if (response.ok) {
        const data = await response.json();
        setImagePath2(data.imagePath);
        setFormData({
          ...formData,
          imageD: data.imagePath,
        });
        console.log('Image uploaded:', data.imagePath);
      } else {
        throw new Error('Error uploading image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${serverURL}silentfeatures/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        Swal.fire("Congrats", "Article updated successfully!", "success").then((result) => {
          if (result.isConfirmed) {
            navigate("/showsilentfeatures");
          }
        });
      } else {
        const error = await response.json();
        console.error("Error:", error);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
        <h2 className="text-2xl font-bold mb-6 text-center">Edit Silent Feature</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-6">
            <label htmlFor="author" className="block text-gray-700 text-sm font-medium mb-2">
              author
            </label>
            <input
              type="text"
              id="author"
              name="author"
              value={formData.author}
              onChange={handleChange}
              required
              className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="mb-6">
            <label htmlFor="imageL" className="block mb-2">
              Image 1:
            </label>
            <input
              type="file"
              id="imageL"
              name="imageL"
              onChange={handleFileChange1}
              className="border rounded p-2 mb-2"
            />
            <button
              onClick={handleUpload1}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Upload Image
            </button>
            {formData.imageL && (
              <img
                src={`${serverURL}` + formData.imageL}
                alt="Uploaded"
                style={{ height: "200px" }}
                className="mt-2"
              />
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="imageD" className="block mb-2">
              Image 2:
            </label>
            <input
              type="file"
              id="imageD"
              name="imageD"
              onChange={handleFileChange2}
              className="border rounded p-2 mb-2"
            />
            <button
              onClick={handleUpload2}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Upload Image
            </button>
            {formData.imageD && (
              <img
                src={`${serverURL}` + formData.imageD}
                alt="Uploaded"
                style={{ height: "200px" }}
                className="mt-2"
              />
            )}
          </div>
          <div className="mb-6">
            <label htmlFor="body" className="block text-gray-700 text-sm font-medium mb-2">
              body
            </label>
            <textarea
              id="body"
              name="body"
              value={formData.body}
              onChange={handleChange}
              required
              rows="5"
              className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-500 w-[100%] hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSilentFeature;
