import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const EditService = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        title: "",
        image: "",
        body: "",
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePath, setImagePath] = useState("");

    useEffect(() => {
        const fetchService = async () => {
            try {
                const response = await fetch(`${serverURL}services/${id}`);
                if (response.ok) {
                    const data = await response.json();
                    setFormData(data);
                } else {
                    console.error("Error:", response.status);
                }
            } catch (err) {
                console.error("Error:", err);
            }
        };

        fetchService();
    }, [id]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        try {
            const formD = new FormData();
            formD.append("image", selectedFile);

            const response = await fetch(`${serverURL}upload`, {
                method: "POST",
                body: formD,
            });

            if (response.ok) {
                const data = await response.json();
                setImagePath(data.imagePath);
                setFormData({
                    ...formData,
                    image: data.imagePath,
                });
                console.log("Image uploaded:", data.imagePath);
            } else {
                throw new Error("Error uploading image");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${serverURL}services/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                Swal.fire("Congrats", "service updated successfully!", "success").then((result) => {
                    if (result.isConfirmed) {
                        navigate("/showservices");
                    }
                });
            } else {
                const error = await response.json();
                console.error("Error:", error);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-center">Edit Service</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label htmlFor="name" className="block text-gray-700 text-sm font-medium mb-2">
                            Title
                        </label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                            className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6">
                        <label htmlFor="image" className="block mb-2">
                            Image:
                        </label>
                        <input
                            type="file"
                            id="image"
                            name="image"
                            onChange={handleFileChange}
                            className="border rounded p-2 mb-2"
                        />
                        <button
                            type="button"
                            onClick={handleUpload}
                            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Upload Image
                        </button>

                        {formData.image && (
                            <img
                                src={`${serverURL}` + formData.image}
                                alt="Uploaded"
                                style={{ height: "200px" }}
                                className="mt-2"
                            />
                        )}
                    </div>
                    <div className="mb-6">
                        <label
                            htmlFor="body"
                            className="block text-gray-700 text-sm font-medium mb-2"
                        >
                            Body
                        </label>
                        <textarea
                            id="body"
                            name="body"
                            value={formData.body}
                            onChange={handleChange}
                            required
                            rows="5"
                            className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="bg-blue-500 w-full hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditService;
