import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const AddArticle = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        content: "",
    });
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${serverURL}line`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                const line = await response.json();
                Swal.fire("Congrats", "Line created successfully!", "success").then(
                    (result) => {
                        if (result.isConfirmed) {
                            navigate("/showline");
                        }
                    }
                );
            } else {
                const error = await response.json();
                console.error("Error:", error);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
                <h2 className="text-2xl font-bold mb-6 text-center">Add Line</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-6">
                        <label htmlFor="content" className="block text-gray-700 text-sm font-medium mb-2">
                            Content
                        </label>
                        <textarea
                            id="content"
                            name="content"
                            value={formData.content}
                            onChange={handleChange}
                            required
                            rows="5"
                            className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="flex justify-center">
                        <button
                            type="submit"
                            className="bg-blue-500 w-[100%] hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
export default AddArticle;
