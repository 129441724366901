import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
} from "@material-tailwind/react";
import { Modal, Label, TextInput } from "flowbite-react";
import { FiDelete, FiEdit, FiPlusCircle, FiTrash } from "react-icons/fi";
import axios from "axios";

const ShowCategories = () => {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [category, setCategory] = useState(null);
  const [name, setName] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${serverURL}category`);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setCategories(data);
      } else {
        console.error("Error:", response.status);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this category. This action cannot be undone.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "Cancel",
      });

      if (result.isConfirmed) {
        try {
          const response = await axios.delete(`${serverURL}category/${id}`);
          fetchCategories();
          Swal.fire("Delete!", "The category has been deleted.", "success");
          handleClose();
        } catch (error) {
          let message = error?.response?.data?.error || error.toString();
          Swal.fire("", message, "error");
        }
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const onClickEdit = async (category) => {
    setCategory(category);
    setName(category.name);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setParentId(null);
    setCategory(null);
    setName("");
  };

  const onAddNewCategory = (parentId) => {
    setParentId(parentId);
    setShowModal(true);
  };

  const ListItem = ({ item, children = false }) => {
    return (
      <Accordion open={true} key={item._id} className="mt-2">
        {children ? (
          <div className="items-center flex">
            <li className="list-disc  text-[16px]">{item.name}</li>
            <div className="flex items-center">
              <FiPlusCircle
                onClick={() => onAddNewCategory(item._id)}
                color="green"
                className="ml-3 cursor-pointer"
              />
              <FiEdit
                onClick={() => onClickEdit(item)}
                color="orange"
                className="ml-3 cursor-pointer"
              />
              <FiTrash
                onClick={() => handleDelete(item._id)}
                color="tomato"
                className="ml-3 cursor-pointer"
              />
            </div>
          </div>
        ) : (
          <AccordionHeader>
            {" "}
            {item.name}{" "}
            <div className="flex items-center">
              <FiPlusCircle
                onClick={() => onAddNewCategory(item._id)}
                color="green"
                className="ml-3 cursor-pointer"
              />{" "}
              <FiEdit
                onClick={() => onClickEdit(item)}
                color="orange"
                className="ml-3 cursor-pointer"
              />
              <FiTrash
                onClick={() => handleDelete(item._id)}
                color="tomato"
                className="ml-3 cursor-pointer"
              />
            </div>
          </AccordionHeader>
        )}

        {item.children && item.children.length > 0 && (
          <ul>
            {item.children.map((child) => (
              <AccordionBody key={child._id} className="ml-9 list-disc">
                <ul>
                  <ListItem item={child} children={true} />
                </ul>
              </AccordionBody>
            ))}
          </ul>
        )}
      </Accordion>
    );
  };

  const List = ({ data }) => {
    return (
      <div className="ml-0 ">
        {data.map((item) => (
          <ListItem key={item._id} item={item} />
        ))}
      </div>
    );
  };

  const onAddCategory = async () => {
    if (!name || !name?.trim()) {
      Swal.fire("Required!", "Please enter name.", "error");
      return;
    }

    try {
      let data = {
        name: name.trim(),
        parent_id: parentId,
      };
      const response = await axios.post(`${serverURL}category`, data);
      fetchCategories();
      Swal.fire("Added!", "The category has been added.", "success");
      handleClose();
    } catch (error) {
      Swal.fire(error.toString(), "error");
      console.error("Error data:", error);
    }
  };

  const onEditCategory = async () => {
    if (!name || !name?.trim()) {
      Swal.fire("Required!", "Please enter name.", "error");
      return;
    }

    try {
      let data = {
        name: name.trim(),
      };
      const response = await axios.put(
        `${serverURL}category/${category._id}`,
        data
      );
      fetchCategories();
      Swal.fire("Updated!", "The category has been updated.", "success");
      handleClose();
    } catch (error) {
      let message = error?.response?.data?.error || error.toString();
      Swal.fire("", message, "error");
      console.error("Error data:", error);
    }
  };
  return (
    <>
      <div className="flex">
        <div className="sidebar-container">
          <Sidebar />
        </div>
        <div className="flex-grow transition-transform duration-300 ease-in-out">
          <div className="p-4">
            <h2 className="text-2xl font-bold mb-6">Categories</h2>
            <Button
              className="p-2 flex ml-auto"
              onClick={() => setShowModal(true)}
            >
              Add new category
            </Button>
            {categories.length === 0 ? (
              <p>No categories found.</p>
            ) : (
              <ul className="space-y-8">
                <List data={categories} />
              </ul>
            )}
          </div>
        </div>
      </div>

      <Modal show={showModal} onClose={handleClose}>
        <Modal.Header>
          {" "}
          {category ? "Edit Category" : "Add Category"}
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div>
              <TextInput
                id="name"
                value={name || ""}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter the category name here"
                required
                type="text"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={category ? onEditCategory : onAddCategory}>
            {category ? "UPDATE" : "ADD"}
          </Button>
          <Button color="gray" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShowCategories;
