import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { serverURL } from "../serverUrl";

const EditFAQ = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        question: "",
        answer: "",
    });

    useEffect(() => {
        const fetchFAQ = async () => {
            try {
                const response = await fetch(`${serverURL}faqs/${id}`); 
                if (response.ok) {
                    const data = await response.json();
                    setFormData(data);
                } else {
                    console.error("Error:", response.status);
                }
            } catch (err) {
                console.error("Error:", err);
            }
        };

        fetchFAQ();
    }, [id]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${serverURL}faqs/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                Swal.fire("Congrats", "FAQ updated successfully!", "success").then((result) => {
                    if (result.isConfirmed) {
                        navigate("/showfaqs");
                    }
                });
            } else {
                const error = await response.json();
                console.error("Error:", error);
            }
        } catch (err) {
            console.error("Error:", err);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <div className="max-w-md w-full bg-white p-8 shadow-lg rounded-lg">
            <h2 className="text-2xl font-bold mb-6 text-center">Add FAQ</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-6">
                    <label
                        htmlFor="name"
                        className="block text-gray-700 text-sm font-medium mb-2"
                    >
                        Question
                    </label>
                    <input
                        type="text"
                        id="question"
                        name="question"
                        value={formData.question}
                        onChange={handleChange}
                        required
                        className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="mb-6">
                    <label
                        htmlFor="description"
                        className="block text-gray-700 text-sm font-medium mb-2"
                    >
                        Answer
                    </label>
                    <textarea
                        id="answer"
                        name="answer"
                        value={formData.answer}
                        onChange={handleChange}
                        required
                        rows="5"
                        className="w-full p-2 border border-gray-300 rounded-md  focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="flex justify-center">
                    <button
                        type="submit"
                        className="bg-blue-500 w-[100%] hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-md"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
    );
};

export default EditFAQ;
