import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { serverURL } from "../serverUrl";

const TableHero = () => {
  const [data, setData] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    column: "",
    direction: "asc",
  });
  const handleSort = (columnName) => {
    const isAsc =
      sortOrder.column === columnName && sortOrder.direction === "asc";
    const direction = isAsc ? "desc" : "asc";
    setSortOrder({ column: columnName, direction });
    setData((prevData) =>
      [...prevData].sort((a, b) => {
        const aValue =
          columnName === "Time" ? new Date(a[columnName]) : a[columnName];
        const bValue =
          columnName === "Time" ? new Date(b[columnName]) : b[columnName];

        // if (typeof aValue === "string" && typeof bValue === "string") {
        //   return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        // }

        return isAsc ? (aValue > bValue ? 1 : -1) : bValue > aValue ? 1 : -1;
      })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${serverURL}GDform`);
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  interface MyObject {
    [key: string]: any;
  }

  function convertArrayToCSV(data: MyObject[], filename: string) {
    const csvContent = generateCSVContent(data);
    const csvData = new Blob([csvContent], { type: "text/csv" });

    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(csvData);
    downloadLink.setAttribute("download", filename);

    downloadLink.click();
  }

  function generateCSVContent(data: MyObject[]): string {
    const keys = Object.keys(data[0]);

    const headerRow = keys.join(",") + "\n";

    const rows = data.map((obj) => {
      const values = keys.map((key) => {
        const value = obj[key];

        if (Array.isArray(value)) {
          return `"${value.join(", ")}"`;
        }

        if (value && value.toString().includes(",")) {
          return `"${value}"`;
        }

        return value;
      });

      return values.join(",");
    });

    return headerRow + rows.join("\n");
  }

  const proper = (old: any) => {
    const date = new Date(old);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateTime;
  };

  return (
    <div className="flex">
      <div className="sidebar-container">
        <Sidebar />
      </div>
      <div
        className={`flex-grow transition-transform duration-300 ease-in-out`}
      >
        <h1 className="text-3xl font-semibold mb-4">
          Customer Survey Form Data
        </h1>
        <button
          onClick={() => convertArrayToCSV(data, "surveyData.csv")}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Save to CSV file
        </button>
        <table className="w-full mt-4 border border-gray-400">
          <thead className="bg-gray-200">
            <tr>
              <th className="p-2">S.No.</th>
              <th
                className="p-2"
                onClick={() => handleSort("name")}
                style={{ cursor: "pointer", textAlign: "right" }}
              >
                Name{" "}
                {sortOrder.column === "name" &&
                sortOrder.direction === "asc" ? (
                  <>&uarr;</>
                ) : (
                  <>&darr;</>
                )}
              </th>
              <th
                className="p-2"
                onClick={() => handleSort("age")}
                style={{ cursor: "pointer", textAlign: "right" }}
              >
                Age{" "}
                {sortOrder.column === "age" && sortOrder.direction === "asc" ? (
                  <>&uarr;</>
                ) : (
                  <>&darr;</>
                )}
              </th>
              <th
                className="p-2"
                onClick={() => handleSort("height")}
                style={{ cursor: "pointer", textAlign: "right" }}
              >
                Height{" "}
                {sortOrder.column === "height" &&
                sortOrder.direction === "asc" ? (
                  <>&uarr;</>
                ) : (
                  <>&darr;</>
                )}
              </th>
              <th
                className="p-2"
                onClick={() => handleSort("weight")}
                style={{ cursor: "pointer", textAlign: "right" }}
              >
                Weight{" "}
                {sortOrder.column === "weight" &&
                sortOrder.direction === "asc" ? (
                  <>&uarr;</>
                ) : (
                  <>&darr;</>
                )}
              </th>
              <th className="p-2">Gender</th>
              <th className="p-2">Food Habits</th>
              <th className="p-2">Body Target</th>
              <th className="p-2">Activities</th>
              <th className="p-2">Frequency</th>
              <th className="p-2">Phone</th>
              <th className="p-2">Email</th>
              <th
                className="p-2"
                onClick={() => handleSort("createdAt")}
                style={{ cursor: "pointer", textAlign: "right" }}
              >
                Time{" "}
                {sortOrder.column === "createdAt" &&
                sortOrder.direction === "asc" ? (
                  <>&uarr;</>
                ) : (
                  <>&darr;</>
                )}
              </th>
            </tr>
          </thead>

          <tbody>
            {data.map((item: any, i) => (
              <tr key={item._id} className={i % 2 === 0 ? "bg-gray-100" : ""}>
                <td className="p-2" style={{ width: "40px" }}>
                  {i + 1}
                </td>
                <td className="p-2" style={{ width: "150px" }}>
                  {item.name}
                </td>
                <td className="p-2" style={{ width: "100px" }}>
                  {item.age}
                </td>
                <td className="p-2" style={{ width: "100px" }}>
                  {item.height}
                </td>
                <td className="p-2" style={{ width: "100px" }}>
                  {item.weight}
                </td>
                <td className="p-2" style={{ width: "120px" }}>
                  {item.gender}
                </td>
                <td className="p-2" style={{ width: "120px" }}>
                  {item.foodHabits}
                </td>
                <td className="p-2" style={{ width: "120px" }}>
                  {item.bodyTarget}
                </td>
                <td className="p-2" style={{ width: "120px" }}>
                  {item.activities}
                </td>
                <td className="p-2" style={{ width: "120px" }}>
                  {item.frequency}
                </td>
                <td className="p-2" style={{ width: "120px" }}>
                  {item.phone}
                </td>
                <td className="p-2" style={{ width: "120px" }}>
                  {item.email}
                </td>
                <td className="p-2" style={{ width: "150px" }}>
                  {proper(item.createdAt)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableHero;
